<template>
  <router-view />
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap");
@import "./ui/assets/styles/variables.css";
@import "./ui/assets/styles/fonts.css";
@import "./ui/assets/styles/antd.css";

body {
  margin: 0;
}

#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.embed-video,
.embed-video iframe {
  border-radius: 16px;
}
</style>
