import { createStore } from "vuex";
import { PlannerType } from "@/domain/interfaces/planner";

export default createStore({
  state: {
    email: "",
    type: "family" as PlannerType,
  },
  getters: {
    userEmail(state) {
      return state.email;
    },
    plannerType(state) {
      return state.type;
    },
  },
  mutations: {
    updateEmail(state, email: string): void {
      state.email = email;
    },
    updateType(state, type: PlannerType): void {
      state.type = type;
    },
  },
  actions: {
    setEmail(context, email: string): void {
      context.commit("updateEmail", email);
    },
    setType(context, type: PlannerType): void {
      context.commit("updateType", type);
    },
  },
});
